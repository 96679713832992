<template>
  <BaseCard padding="0px" :margin="margin">
    <img
      v-if="uploadedImage"
      width="100%"
      :src="uploadedImage"
      :style="{ width: '100%', 'object-fit': 'cover' }"
    />
    <div v-if="!uploadedImage" class="button">
      <slot>
        <input
          ref="fileUpload"
          type="file"
          accept=".png,.jpg,.jpeg,.webp"
          @change="Files($event)"
          :multiple="isMultiple"
          hidden
        />
        <Button
          class="btn-slateblue"
          :label="buttonLabel"
          padding="11px 20px"
          icon="custom-upload"
          icon-position="right"
          @click="fileUpload"
        >
        </Button>
      </slot>
    </div>
    <div class="panel" v-if="uploadedImage">
      <div class="label">
        <span class="upload">
          {{ upload }}
        </span>
        <span class="text">
          {{ text }}
        </span>
      </div>
      <div class="buttons">
        <a v-if="link.includes('blob\:htt')" :href="link" target="_blank">
          <CircleButton
            class="link"
            icon="custom-external-link-light"
            :color="`${colorsHtml.light_text}`"
            :bg-color="`${colorsHtml.background_3}`"
            font-size="20px"
          />
        </a>
        <router-link v-else :to="link" target="_blank">
          <CircleButton
            class="link"
            icon="custom-external-link-light"
            :color="`${colorsHtml.light_text}`"
            :bg-color="`${colorsHtml.background_3}`"
            font-size="20px"
          />
        </router-link>
        <CircleButton
          v-if="action !== false"
          icon="custom-times"
          :color="`${colorsHtml.light_text}`"
          :bg-color="`${colorsHtml.background_3}`"
          font-size="26px"
          @click.native="action"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import CircleButton from "@/components/Buttons/CircleButton";
import Button from "@/components/Buttons/Button";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  customUpload,
  customTimes,
  customExternalLinkLight,
} from "@/assets/icons";
library.add(customUpload, customTimes, customExternalLinkLight);
import colorsHtml from "/colors.config.json";
export default {
  name: "ModuleContentImage",
  components: {
    BaseCard,
    CircleButton,
    Button,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    upload: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    action: {
      type: [Function, Boolean],
      default: () => false,
    },
    link: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "",
    },
    margin: {
      type: String,
      default: "30px",
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uploadedImage: this.image,
      colorsHtml: colorsHtml,
    };
  },
  methods: {
    fileUpload() {
      this.$refs.fileUpload.click();
    },
    Files(event) {
      let images = {
        localUrls: [],
        images: [],
      };
      [...event.target.files].forEach((item) => {
        images.localUrls.push(URL.createObjectURL(item));
        images.images.push(item);
      });

      this.$emit("addImage", images, "image");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .card-arrow {
  margin-bottom: 0;
  svg {
    margin-left: 0;
  }
}
.button {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
.panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  word-break: break-word;
  .label {
    font-family: "Oswald", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 55px;
    .upload {
      color: $dark_text;
    }
    .text {
      color: $light_text;
    }
  }
  .buttons {
    display: flex;
    .card-arrow {
      margin-left: 15px;
    }
  }
}
</style>
