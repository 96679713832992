import { render, staticRenderFns } from "./ModuleContentVideo.vue?vue&type=template&id=78dd55e5&scoped=true&"
import script from "./ModuleContentVideo.vue?vue&type=script&lang=js&"
export * from "./ModuleContentVideo.vue?vue&type=script&lang=js&"
import style0 from "./ModuleContentVideo.vue?vue&type=style&index=0&id=78dd55e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78dd55e5",
  null
  
)

export default component.exports