<template>
  <div :class="['mobile-hidden-card', { 'card-opened': cardOpened }]">
    <div class="card-content" id="mobile-hidden-card-content">
      <slot name="content" />

      <div class="close-card" @click="cardOpened = false"></div>
    </div>

    <div class="open-card" @click="cardOpened = true">{{ btnLabel }}</div>
    <div class="scroll-to-card" id="scroll-to-card" @click="scrollToElement">
      {{ btnLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileHiddenCard",
  data() {
    return {
      cardOpened: false,
    };
  },
  props: {
    btnLabel: {
      type: String,
      default: "",
    },
  },
  methods: {
    scrollToElement() {
      let element = document.getElementById("mobile-hidden-card-content");
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    },
    isElementInViewport() {
      let button = document.getElementById("scroll-to-card");
      let element = document.getElementById("mobile-hidden-card-content");
      let elementBoundaries = element.getBoundingClientRect();

      if (
        elementBoundaries.bottom > elementBoundaries.height &&
        elementBoundaries.bottom < window.innerHeight
      ) {
        button.style.display = "none";
      } else if (window.innerWidth > 767 && window.innerWidth < 1200) {
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.isElementInViewport);
    window.addEventListener("resize", this.isElementInViewport);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.isElementInViewport);
    window.removeEventListener("resize", this.isElementInViewport);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.mobile-hidden-card {
  @include transition--ease-out;

  @media (min-width: 768px) {
    position: static;
    margin-bottom: 15px;

    &__card {
      flex: 0 0 320px;
      max-width: 320px;
    }
  }

  @media (min-width: 1200px) {
    margin-bottom: 30px;
  }

  @media (min-width: 1600px) {
    margin-bottom: 50px;
  }

  &.card-opened {
    .card-content {
      bottom: 70px;
    }
  }

  &:not(.card-opened) {
    .card-content {
      bottom: -700px;
    }
  }

  .card-content {
    @media (max-width: 767px) {
      @include transition--ease-out;
      position: fixed;
      left: 0;
      // background-image: url('../../assets/BACKGRAY-1.jpg');
      background-color: $white;
      background-repeat: repeat;
      box-shadow: 0px 0px 17px 12px #0000001f;
      border-radius: 15px 15px 0 0;
      padding: 58px 15px 74px;
      width: 100%;
      z-index: 950;
    }
  }

  .close-card {
    @media (max-width: 767px) {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #efefef;
      color: $white;
      transform: rotate(45deg);
      text-decoration: none;
      cursor: pointer;
      z-index: 951;

      &:before,
      &:after {
        position: absolute;
        content: "";
        width: 14px;
        height: 1px;
        background: $black;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
  }

  .open-card {
    display: none;

    @media (max-width: 767px) {
      display: block;
      position: fixed;
      z-index: 900;
      right: 0px;
      left: 0;
      border-radius: 0;
      bottom: 74px;
      height: 43px;
      margin-bottom: 0px;
      background: $navy;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding: 11px 20px;
      text-align: center;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      @include transition--ease-out;
      cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }
  }

  .scroll-to-card {
    width: 225px;
    box-shadow: -2px 0px 23px -6px #00000026;
    display: none;
    position: fixed;
    z-index: 3;
    right: 15px;
    bottom: 15px;
    background-color: $navy;
    font-size: 14px;
    text-align: center;
    padding: 15px;
    color: $white;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 1199px) {
      display: block;
    }
  }
}
</style>
